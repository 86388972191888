<template>
  <div>
    <div v-if="appointments.length == 0" class="alert alert-info">
      <p>{{ $t('admin._appointments.no_appointments') }}.</p>
    </div>
    <div v-else>
      <table class="table">
        <thead>
          <tr>
            <th>Termin</th>
            <th>Name</th>
            <th>Leistungen</th>
            <th>Kosten</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="appointment in appointments" v-bind:key="appointment.id">
            <td width="20%">
              <span class="appointment-meta">
                <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}</span>
                <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number }}</span>
                <i>|</i>
                <span v-if="appointment.actual_duration">{{ appointment.actual_duration }} min</span>
                <span v-else>{{ appointment.duration }} min</span>
              </span>
              <span class="appointment-timestamp">
                {{ appointment.date_formatted }}, {{ appointment.time_formatted }}
              </span>
            </td>
            <td width="20%">
              {{ appointment.last_name }}, {{ appointment.first_name }}
            </td>
            <td width="25%">
              <div v-for="service in appointment.services" v-bind:key="service.id" style="margin-bottom: 10px;">
                <span class="category-name">{{ service.location_name }} / {{ service.category_name }}</span>
                <span class="service-name">{{ service.service_name }} <small
                    v-if="appointment.is_solo_appointment && service.service_amount && service.service_reduced_duration">({{
                      service.service_amount * service.service_reduced_duration
                    }} min.)</small><small v-else-if="service.service_amount && service.service_duration">({{
  service.service_amount * service.service_duration
}} min.)</small></span>
              </div>
            </td>
            <td width="10%">
              <span v-if="appointment.status == 'completed' && appointment.service_price > 0.0">
                <span v-if="appointment.payment_status == 'paid'" class="price" style="color:#07B400;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>{{ new Intl.NumberFormat('de-DE', {
                    style: 'currency', currency: 'eur',
                  }).format(appointment.service_price) }}</span>
                </span>
                <span v-else class="price" style="color:#CC0000;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>{{ new Intl.NumberFormat('de-DE', {
                    style: 'currency', currency: 'eur',
                  }).format(appointment.service_price) }}</span>
                </span>
              </span>
            </td>
            <td width="15%">
              <span v-if="appointment.status == 'created'" class="status-created">
                <span class="status-icon"></span>
                <span class="status-text">Reserviert</span>
              </span>
              <span v-if="appointment.status == 'not_appeared'" class="status-created">
                <span class="status-icon"></span>
                <span class="status-text">Nicht erschienen</span>
              </span>
              <span v-if="appointment.status == 'checked_in'" class="status-checked_in">
                <span class="status-icon"></span>
                <span class="status-text">Eingecheckt</span>
              </span>
              <span v-if="appointment.status == 'called'" class="status-called">
                <span class="status-icon"></span>
                <span class="status-text">Aufgerufen</span>
              </span>
              <span v-if="appointment.status == 'running'" class="status-running">
                <span class="status-icon"></span>
                <span class="status-text">Läuft</span>
              </span>
              <span v-if="appointment.status == 'canceled'" class="status-canceled">
                <span class="status-icon"></span>
                <span class="status-text">Storniert</span>
              </span>
              <span v-if="appointment.status == 'completed'" class="status-completed">
                <span class="status-icon"></span>
                <span class="status-text">Fertig</span>
              </span>
              <span v-if="appointment.status == 'paused'" class="status-paused">
                <span class="status-icon"></span>
                <span class="status-text">Pausiert</span>
              </span>
              <span
                v-if="(appointment.counter && appointment.counter.name) || (appointment.location.appointment_mode == 'staff_based' && appointment.user_name)"
                style="font-size:12px;display:block;margin-top:3px;margin-left:15px;color:#a4a4a4">
                <span v-if="appointment.counter && appointment.counter.name">{{ appointment.counter.name }}</span> <small
                  v-if="appointment.location.appointment_mode == 'staff_based' && appointment.user_name">[{{
                    appointment.user_name }}]</small>
              </span>
            </td>
            <td width="10%" class="actions">
              <router-link :to="'/admin/appointments/' + appointment.id">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
              </router-link>
              <a v-if="user.role == 'administrator' && appointment.payment_status == 'pending'"
                @click="delete_appointment(appointment)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appointments',
  props: ['appointments', 'user'],
  methods: {
    delete_appointment(appointment) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/appointments/' + appointment.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
            this.$emit('get_appointments')
          })
      }
    },
  }
}
</script>
